import { http } from '@/http';

const path = '/credito';

class CreditoService {

  static async findAll(idPessoa) {
    try {
      const { data } = await http.get(`${path}/all?idPessoa=${idPessoa}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar os Inscrições.', error);
      throw error;
    }
  }

  static async findInscricao(idCliente, inscricao) {
    try {
      const { data } = await http.get(`${path}/pesquisarInscricao?idCliente=${idCliente}&inscricao=${inscricao}`);
      return data;
    } catch (error) {
      console.log('Erro ao consultar a Inscrição.', error);
      throw error;
    }
  }

  static async inserirInscricao(inscricao) {
    try {

      console.log(inscricao[0]);

      const { data } = await http.post(`${path}/inserirInscricao`, inscricao);
      return data;
    } catch (error) {
      console.log('Erro ao buscar token.', error);
      throw error;
    }
  }

  static async removerInscricao(id) {
    try {

      const { data } = await http.delete(`${path}/removerInscricao?id=${id}`);
      return data;
    } catch (error) {
      console.log('Erro ao remover inscricao.', error);
      throw error;
    }
  }

  static async ultimaSequencia(idPessoa) {
    try {
      const { data } = await http.get(`${path}/ultimaSequencia?idPessoa=${idPessoa}`);
      return data;
    } catch (error) {
      console.log('Erro ao consultar sequencia.', error);
      throw error;
    }
  }

  static async verificaDataLimiteAdd(idCliente) {
    try {
      const { data } = await http.get(`${path}/verificaDataLimiteAdd?idCliente=${idCliente}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar data.', error);
      throw error;
    }
  }


}

export default CreditoService;