import { http } from '@/http';

const path = '/extrato-credito';

class ExtratoCreditoService {

  static async findAll(idCliente, linkServer, documento) {
    try {
      const { data } = await http.get(`${path}/all?idCliente=${idCliente}&linkServer=${linkServer}&documento=${documento}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar os extratos de crédito.', error);
      throw error;
    }
  }


  static async findSaldo(idCliente, idPessoa) {
    try {
      const { data } = await http.get(`${path}/saldo?idCliente=${idCliente}&idPessoa=${idPessoa}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar os extratos de crédito.', error);
      throw error;
    }
  }

  static async findHistorico(idPessoa) {
    try {
      const { data } = await http.get(`${path}/historico?idPessoa=${idPessoa}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar o histórico.', error);
      throw error;
    }
  }

  static async imprimirNota(url) {
    try {
      const config = {
        responseType: 'arraybuffer',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/pdf',
        },
      };

      const { data } = await http.post(url, {}, config);

      const blob = new Blob([data], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL, '_blank');
    } catch (error) {
      console.log('Erro ao imprimir a nota.', error);
      throw error;
    }
  }

}

export default ExtratoCreditoService;