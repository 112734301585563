import { http } from '@/http';

const path = '/dam';

class DamService {

  static async findAll(consultarDam) {
    try {
      const { data } = await http.post(`${path}/all`, consultarDam );
      return data;
    } catch (error) {
      console.log('Erro ao consultar o DAM.', error);
      throw error;
    }
  }

  static async findOrigemCalculo(idCliente, idFuncionalidade) {
    try {
      const { data } = await http.get(`${path}/origemCalculo?idCliente=${idCliente}&idFuncionalidade=${idFuncionalidade}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar DAM.', error);
      throw error;
    }
  }

  static async findTipoFiltroDam(idCliente, idFuncionalidade, idOrigemCalculo) {
    try {
      const { data } = await http.get(`${path}/tipoFiltroDam?idCliente=${idCliente}&idFuncionalidade=${idFuncionalidade}&idOrigemCalculo=${idOrigemCalculo}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar os tipos de filtro.', error);
      throw error;
    }
  }

}

export default DamService;